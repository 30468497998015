import { createCookie } from "@remix-run/node"
import { useFetchers } from "@remix-run/react"
import { serverOnly$ } from "vite-env-only/macros"
import { FetcherKey } from "../../../lib/fetcher"
import { useRootLoaderData } from "../../../lib/root-loader-data"

export const isSidebarHiddenCookie = serverOnly$(
  createCookie("isSidebarHidden"),
)

export function useIsSidebarHidden() {
  const rootLoaderData = useRootLoaderData()

  const fetchers = useFetchers()

  const fetcher = fetchers.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (fetcher) => fetcher.key == FetcherKey.SidebarVisibility,
  )

  return fetcher?.formData
    ? fetcher.formData.get("sidebarVisibility") === "hidden"
    : rootLoaderData.isSidebarHidden
}
