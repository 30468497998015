import type { useFetchers } from "@remix-run/react"

export enum FetcherKey {
  SidebarVisibility = "sidebar-visibility",
  PricingCostsType = "pricing-costs-type",
}

export type FetcherWithFormData = ReturnType<typeof useFetchers>[number] & {
  formData: FormData
}
