import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu"
import { Form, Link } from "@remix-run/react"
import { IconArrowBarLeft, IconSettings } from "@tabler/icons-react"
import type { ReactNode } from "react"
import { FetcherKey } from "../../../lib/fetcher"
import { IconButton } from "../../button"
import { cn } from "../../cn"
import { useIsSidebarHidden } from "./sidebar-visibility"
import { SidebarLink, SidebarLinkIcon } from "."

interface Props {
  children?: ReactNode
  viewerItem: ReactNode
}

export function Sidebar({ children, viewerItem }: Props) {
  const isHidden = useIsSidebarHidden()

  return (
    <aside
      className={cn(
        "sticky top-0 flex h-screen flex-col px-2 py-3 transition-opacity duration-100",
        isHidden && "opacity-0",
      )}
    >
      {viewerItem}
      <NavigationMenuPrimitive.Root orientation="vertical">
        <NavigationMenuPrimitive.List className="flex flex-col gap-y-8">
          <SidebarItem>
            <SidebarLink asChild>
              <Link to="/settings" prefetch="intent">
                <SidebarLinkIcon Icon={IconSettings} />
                Настройки
              </Link>
            </SidebarLink>
          </SidebarItem>
          <div className="flex flex-col gap-y-0.5">{children}</div>
        </NavigationMenuPrimitive.List>
      </NavigationMenuPrimitive.Root>
      <Form
        action="/sidebar-visibility"
        method="post"
        navigate={false}
        fetcherKey={FetcherKey.SidebarVisibility}
        className="mt-auto block self-end"
      >
        <IconButton
          aria-label="Закрыть сайдбар"
          name="sidebarVisibility"
          value="hidden"
          type="submit"
          variant="tertiary"
        >
          <IconArrowBarLeft size={16} />
        </IconButton>
      </Form>
    </aside>
  )
}

export const SidebarItem = NavigationMenuPrimitive.Item
