import { Slot } from "@radix-ui/react-slot"
import { type ComponentPropsWithoutRef, forwardRef } from "react"
import { tv, type VariantProps } from "../tv"

const iconButton = tv({
  base: "inline-flex aspect-square items-center justify-center text-center transition-colors disabled:pointer-events-none",
  variants: {
    variant: {
      secondary: "",
      tertiary: "",
      floating:
        "rounded border border-transparent text-text-tertiary shadow-light transition-colors hover:border-transparent-light hover:bg-transparent-lighter active:border-transparent active:bg-transparent-medium",
    },
    colorScheme: {
      light: "",
    },
    size: {
      sm: "w-6 duration-75",
      md: "w-8 duration-100",
    },
  },
  compoundVariants: [
    {
      colorScheme: "light",
      className: "rounded",
    },
    {
      colorScheme: "light",
      variant: "secondary",
      className:
        "border border-transparent text-text-secondary hover:bg-transparent-light focus-visible:border-blue focus-visible:outline-none focus-visible:ring active:bg-transparent-medium disabled:text-text-extraLight",
    },
    {
      colorScheme: "light",
      variant: "tertiary",
      className:
        "text-text-tertiary hover:bg-transparent-light active:bg-transparent-medium disabled:text-text-extraLight",
    },
  ],
  defaultVariants: {
    variant: "secondary",
    colorScheme: "light",
    size: "sm",
  },
})

interface Props
  extends ComponentPropsWithoutRef<"button">,
    VariantProps<typeof iconButton> {
  asChild?: boolean
  "aria-label": string
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ asChild, variant, colorScheme, size, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={iconButton({ variant, colorScheme, size, className })}
        ref={ref}
        {...props}
      />
    )
  },
)
IconButton.displayName = "IconButton"
