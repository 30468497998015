import type { ComponentPropsWithoutRef, ReactNode } from "react"
import { cn } from "../cn"
import { useIsSidebarHidden } from "./sidebar"

interface Props extends ComponentPropsWithoutRef<"main"> {
  sidebar: ReactNode
  header: ReactNode
  children?: ReactNode
  backgroundColor?: "primary" | "secondary"
}

export function Layout({
  sidebar,
  children,
  header,
  className,
  backgroundColor = "primary",
  ...props
}: Props) {
  const isSidebarHidden = useIsSidebarHidden()

  return (
    <div
      className={cn(
        "grid min-h-screen grid-cols-[200px_minmax(0,1fr)] grid-rows-[56px_1fr] transition-all duration-300",
        isSidebarHidden && "-ml-[200px]",
        backgroundColor === "primary" && "bg-bg-primary",
        backgroundColor === "secondary" && "bg-bg-secondary",
      )}
      style={{
        gridTemplateAreas: `'sidebar header' 'sidebar main'`,
      }}
    >
      <div
        className={cn(
          "bg-bg-secondary [grid-area:sidebar]",
          backgroundColor === "secondary" && "border-r border-borders-medium",
        )}
      >
        {sidebar}
      </div>
      <div className="[grid-area:header]">{header}</div>
      <main className={cn("p-6 pt-16 [grid-area:main]", className)} {...props}>
        {children}
      </main>
    </div>
  )
}
