import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { startTransition, StrictMode, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"

Sentry.init({
  dsn: "https://d8d17c3cfd0996c05197547c3ee79631@o4505042211766272.ingest.us.sentry.io/4506333765304320",
  enabled: process.env.NODE_ENV !== "development",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/flow\.qqcoffee\.ru/],

  // Only capture Replay for sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
