import { useRouteLoaderData } from "@remix-run/react"
import type { loader } from "@/app/root"

export function useRootLoaderData() {
  const rootLoaderData = useRouteLoaderData<typeof loader>("root")

  if (!rootLoaderData) {
    throw new Error("Root loader data is not available")
  }

  return rootLoaderData
}
