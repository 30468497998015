import { Form, Link } from "@remix-run/react"
import { IconLogout, IconUser } from "@tabler/icons-react"
import { useViewer } from "@/entities/viewer"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/ui/dropdown-menu"
export function ViewerItem() {
  const viewer = useViewer()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Link
          to="/settings"
          className="flex items-center justify-start gap-x-2 rounded px-1 py-2 text-strong transition-colors duration-100 hover:bg-transparent-light focus-visible:outline-none focus-visible:ring"
        >
          <div className="grid size-4 place-content-center rounded-full border border-borders-medium bg-bg-primary text-[10px] leading-none">
            {viewer.firstName.slice(0, 1)}
          </div>
          <span>
            {viewer.firstName} {viewer.lastName.slice(0, 1)}.
          </span>
        </Link>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[204px]" align="start">
        <DropdownMenuLabel>
          {viewer.firstName} {viewer.lastName}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link to="/settings" prefetch="intent">
              <IconUser className="mr-2 size-4" />
              <span>Профиль</span>
            </Link>
          </DropdownMenuItem>
          <Form action="/auth/logout" method="post">
            <DropdownMenuItem asChild>
              <button>
                <IconLogout className="mr-2 size-4" />
                <span>Выйти</span>
              </button>
            </DropdownMenuItem>
          </Form>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
