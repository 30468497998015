import { type LoaderFunctionArgs } from "@remix-run/node"
import { getAuthFromRequest } from "@/features/auth.server"
import { useViewer } from "@/entities/viewer"
import { PageTitle } from "@/shared/ui/layout"

export async function loader({ request }: LoaderFunctionArgs) {
  await getAuthFromRequest(request)

  return null
}

export default function IndexPage() {
  const viewer = useViewer()

  return <PageTitle>Добро пожаловать, {viewer.firstName}</PageTitle>
}
