import { Form } from "@remix-run/react"
import { IconArrowBarRight } from "@tabler/icons-react"
import type { ComponentPropsWithoutRef } from "react"
import { FetcherKey } from "../../../lib/fetcher"
import { IconButton } from "../../button"

type Props = Omit<
  ComponentPropsWithoutRef<typeof Form>,
  "action" | "method" | "navigate" | "fetcherKey"
>

export function ShowSidebarButton(props: Props) {
  return (
    <Form
      action="/sidebar-visibility"
      method="post"
      navigate={false}
      fetcherKey={FetcherKey.SidebarVisibility}
      {...props}
    >
      <IconButton
        aria-label="Открыть сайдбар"
        variant="tertiary"
        name="sidebarVisibility"
        value="visible"
        type="submit"
      >
        <IconArrowBarRight size={16} />
      </IconButton>
    </Form>
  )
}
