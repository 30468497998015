import type { ComponentPropsWithoutRef } from "react"
import { cn } from "../../cn"
import { useIsSidebarHidden } from "../sidebar"
import { Breadcrumbs } from "./Breadcrumbs"
import { ShowSidebarButton } from "./ShowSidebarButton"

interface Props extends ComponentPropsWithoutRef<"header"> {
  shouldShowBreadcrumbs?: boolean
}

export function Header({
  className,
  shouldShowBreadcrumbs = true,
  children,
  ...props
}: Props) {
  const isSidebarHidden = useIsSidebarHidden()

  return (
    <header
      className={cn("flex h-full items-center justify-between px-3", className)}
      {...props}
    >
      <div className="flex items-center justify-start">
        {isSidebarHidden && <ShowSidebarButton className="mr-3" />}
        {shouldShowBreadcrumbs && <Breadcrumbs />}
      </div>
      <div>{children}</div>
    </header>
  )
}
