import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu"
import type { ComponentPropsWithoutRef, ComponentType, ReactNode } from "react"
import { cn } from "../../cn"

interface Props
  extends ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link> {
  children?: ReactNode
}

export function Link({ children, className, ...props }: Props) {
  return (
    <NavigationMenuPrimitive.Link
      className={cn(
        "relative flex h-6 items-center gap-x-2 rounded p-1 pl-[28px] text-text-secondary transition-colors duration-75 hover:bg-transparent-light active:text-text-primary aria-disabled:pointer-events-none aria-disabled:text-text-light [&.active]:bg-transparent-light [&.active]:text-text-primary",
        className,
      )}
      {...props}
    >
      {children}
    </NavigationMenuPrimitive.Link>
  )
}

export function LinkIcon({
  Icon,
  color,
}: {
  Icon: ComponentType<{ className?: string }>
  color?: "blue" | "turquoise" | "green" | "orange" | "sky" | "red" | "yellow"
}) {
  return (
    <Icon
      className={cn(
        "absolute left-1 top-1/2 inline-block h-4 w-4 -translate-y-1/2",
        color === "blue" && "text-blue",
        color === "turquoise" && "text-turquoise-50",
        color === "green" && "text-green-50",
        color === "orange" && "text-orange",
        color === "sky" && "text-sky-40",
        color === "red" && "text-red-50",
        color === "yellow" && "text-yellow",
      )}
    />
  )
}

export function LinkBadge({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  return (
    <div
      role="status"
      className={cn(
        "ml-auto grid size-3.5 place-content-center rounded-full bg-blue font-semibold leading-none text-text-inverted",
        className,
      )}
      style={{ fontSize: "0.6875rem" }}
      {...props}
    >
      {children}
    </div>
  )
}
