import { Outlet } from "@remix-run/react"
import { Sidebar } from "@/widgets/sidebar"
import { Header, Layout } from "@/shared/ui/layout"

export default function AppLayout() {
  return (
    <Layout sidebar={<Sidebar />} header={<Header />}>
      <Outlet />
    </Layout>
  )
}
