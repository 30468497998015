import type { PermissionsResourcesActions } from "@flow/backend"
import type { User } from "@/entities/user"
import type { Resource } from "./types"

/**
 * Checks if a user has permission to perform a certain action on a resource.
 * @param  user - The user to check permissions for.
 * @param resource - The resource to check permissions for.
 * @param action - The action to check permissions for.
 * @returns {boolean} - True if the user has permission, false otherwise.
 */
// eslint-disable-next-line max-params
export function checkPermissions<R extends Resource>(
  user: User,
  resource: R,
  action: PermissionsResourcesActions[R][number],
) {
  if (user.isBanned) {
    return false
  }

  if (user.role.isAdmin) {
    return true
  }

  const permission = user.role.permissions.find(
    (permission) =>
      permission.resource === resource && permission.action === action,
  )

  return Boolean(permission)
}
