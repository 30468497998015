import * as Sentry from "@sentry/remix"
import { useEffect } from "react"
import { useRootLoaderData } from "@/shared/lib/root-loader-data"

export function useViewer() {
  const rootLoaderData = useRootLoaderData()

  useEffect(() => {
    if (rootLoaderData.viewer?.email) {
      Sentry.setUser({ email: rootLoaderData.viewer.email })
      return
    }

    Sentry.setUser(null)
  }, [rootLoaderData.viewer?.email])

  if (!rootLoaderData.viewer) {
    throw new Error("No viewer found in the root loader.")
  }

  return rootLoaderData.viewer
}
