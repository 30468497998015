import type { ComponentPropsWithoutRef, ReactNode } from "react"
import { cn } from "../cn"

interface Props extends ComponentPropsWithoutRef<"h1"> {
  children?: ReactNode
}

export function PageTitle({ children, className, ...props }: Props) {
  return (
    <h1
      className={cn("text-marketing-large text-gray-100", className)}
      {...props}
    >
      {children}
    </h1>
  )
}
