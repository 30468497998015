import { useMatches, type UIMatch } from "@remix-run/react"
import type { ReactElement } from "react"

/**
 * Renders a breadcrumb navigation component based on the current route matches.
 * Breadcrumbs are defined in the `handle.breadcrumb` property of the route.
 * 
 * @returns A React component that renders the breadcrumb navigation.
 * 
 * @example
  export const handle = {
    breadcrumb: (match: UIMatch<typeof loader>) => <>{match.data.name}</>,
  };
  * @example
  export const handle = {
    breadcrumb: () => <Link to="/" intent="prefetch">Главная</Link>,
  };
 */
export function Breadcrumbs() {
  const matches = useMatches()

  const breadcrumbMatches = matches.filter(
    (match) =>
      typeof match.handle === "object" &&
      match.handle &&
      "breadcrumb" in match.handle,
  )

  if (breadcrumbMatches.length === 0) {
    return null
  }

  return (
    <ol className="flex gap-x-1.5 px-1">
      {breadcrumbMatches.map((match) => (
        <li
          className="flex gap-x-1.5 after:block after:text-text-tertiary after:content-['/'] last:after:hidden"
          key={match.pathname}
        >
          {(
            match.handle as { breadcrumb: (match?: UIMatch) => ReactElement }
          ).breadcrumb(match)}
        </li>
      ))}
    </ol>
  )
}
