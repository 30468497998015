import { NavLink } from "@remix-run/react"
import {
  IconBrandTelegram,
  IconCurrencyDollar,
  IconFlame,
  IconLayoutGrid,
  IconLink,
  IconReceipt,
  IconRepeat,
} from "@tabler/icons-react"
import { checkPermissions } from "@/features/permissions"
import { useViewer } from "@/entities/viewer"
import {
  Sidebar as LayoutSidebar,
  SidebarItem,
  SidebarLink,
  SidebarLinkIcon,
} from "@/shared/ui/layout"
import { ViewerItem } from "./ViewerItem"

export function Sidebar() {
  const viewer = useViewer()

  const isPermittedAmocrmMoysklad =
    checkPermissions(viewer, "moysklad-customer-orders", "read") &&
    checkPermissions(viewer, "amocrm-leads", "read") &&
    checkPermissions(viewer, "amocrm-moysklad", "update")

  const isPermittedPricing =
    checkPermissions(viewer, "pricing-settings", "read") &&
    checkPermissions(viewer, "pricing-markups", "read") &&
    checkPermissions(viewer, "pricing-costs", "read") &&
    checkPermissions(viewer, "green-coffees", "read")

  const isPermittedReceipts =
    checkPermissions(viewer, "moysklad-customer-orders", "read") &&
    checkPermissions(viewer, "moysklad-customer-orders", "update") &&
    checkPermissions(viewer, "receipts", "create")

  return (
    <LayoutSidebar viewerItem={<ViewerItem />}>
      {checkPermissions(viewer, "recurring-tasks", "read") && (
        <SidebarItem>
          <SidebarLink asChild>
            <NavLink to="/recurring-tasks" prefetch="intent">
              <SidebarLinkIcon Icon={IconRepeat} color="turquoise" />
              Повтор. задачи
            </NavLink>
          </SidebarLink>
        </SidebarItem>
      )}
      {isPermittedAmocrmMoysklad && (
        <>
          <SidebarItem>
            <SidebarLink asChild>
              <NavLink to="/amocrm-moysklad" prefetch="intent" end>
                <SidebarLinkIcon Icon={IconLink} color="blue" />
                АмоCRM x МС
              </NavLink>
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink asChild>
              <NavLink to="/amocrm-moysklad/logs" prefetch="intent">
                Логи
              </NavLink>
            </SidebarLink>
          </SidebarItem>
        </>
      )}
      {isPermittedReceipts && (
        <SidebarItem>
          <SidebarLink asChild>
            <NavLink to="/receipts" prefetch="intent" end>
              <SidebarLinkIcon Icon={IconReceipt} color="red" />
              Чеки
            </NavLink>
          </SidebarLink>
        </SidebarItem>
      )}
      {checkPermissions(viewer, "b2b-newsletter", "read") && (
        <SidebarItem>
          <SidebarLink asChild>
            <NavLink to="/b2b-newsletter" prefetch="intent" end>
              <SidebarLinkIcon Icon={IconBrandTelegram} color="sky" />
              B2B рассылка
            </NavLink>
          </SidebarLink>
        </SidebarItem>
      )}
      {checkPermissions(viewer, "coffees", "read") && (
        <SidebarItem>
          <SidebarLink asChild>
            <NavLink to="/products" prefetch="intent">
              <SidebarLinkIcon Icon={IconLayoutGrid} color="orange" />
              Товары
            </NavLink>
          </SidebarLink>
        </SidebarItem>
      )}

      {isPermittedPricing && (
        <>
          <SidebarItem>
            <SidebarLink asChild>
              <NavLink to="/pricing" prefetch="intent" end>
                <SidebarLinkIcon Icon={IconCurrencyDollar} color="green" />
                Ценообразование
              </NavLink>
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink asChild>
              <NavLink to="/pricing/green-coffees" prefetch="intent">
                Зелёный кофе
              </NavLink>
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink asChild>
              <NavLink to="/pricing/costs" prefetch="intent">
                Расходники
              </NavLink>
            </SidebarLink>
          </SidebarItem>
        </>
      )}
      {checkPermissions(viewer, "roasts", "create") && (
        <SidebarItem>
          <SidebarLink asChild>
            <NavLink to="/roasts" prefetch="intent">
              <SidebarLinkIcon Icon={IconFlame} color="yellow" />
              Обжарки
            </NavLink>
          </SidebarLink>
        </SidebarItem>
      )}
    </LayoutSidebar>
  )
}
